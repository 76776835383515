:root {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-family: "Inter", sans-serif;

  background: #fffaf4;
  color: #2e2d2d;

  --background: #fffaf4;
  --primary: #f7941f;
  --text-primary: #2e2d2d;
  --text-secondary: #606060;
  --text-error: tomato;

  --dropdown-bg: #fff;

  --card-background: #fff;

  --success: #6daf01;
  --error: 204, 44, 61;

  --completed: #e4fce3;
  --missed: #fff5dc;
  --cancelled: #ffd8dc;

  --btn-primary: linear-gradient(180deg, #f7941f 0%, #f76d1f 100%);
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input,
p,
span,
head,
textarea {
  font-family: "Inter", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background: var(--background);
  color: #2e2d2d;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.mx {
  max-width: 1400px;
  margin: 0 auto;
}

h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--text-primary);
}

h3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 130%;
  color: var(--text-primary);
}

h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  color: var(--text-primary);
}

h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  color: var(--text-primary);
}

.primary {
  color: var(--primary);
}

.text-primary {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--text-primary);
}

.text-secondary {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--text-secondary);
}

.text-error {
  color: rgb(var(--error));
}

.text-success {
  color: var(--success);
}

.s-16 {
  font-size: 16px;
}

.s-14 {
  font-size: 14px;
}

.s-12 {
  font-size: 12px;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
}

.pad {
  padding-left: 40px;
  padding-right: 40px;
}

.section-title {
  color: var(--text-primary);
  font-size: 20px;
  font-weight: 700;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.session-card {
  border-radius: 20px;
  border: 0.7px solid #ede7df;
  background: var(--white, #fff);
  padding: 25px;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
}

.align-center {
  align-items: center;
}

.place-center {
  align-items: center;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.color-primary {
  color: var(--primary) !important;
}

.page-container {
  padding: 30px 0;
}

@media (max-width: 768px) {
  .pad {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 600px) {
  .pad {
    padding-left: 20px;
    padding-right: 20px;
  }

  .section-title {
    font-size: 18px;
  }

  p {
    font-size: 14px;
  }

  .s-14 {
    font-size: 14px;
  }

  .s-16 {
    font-size: 16px;
  }

  .s-12 {
    font-size: 11px;
  }
}

.icon-btn {
  all: unset;
  cursor: pointer;
}

.icon-btn:disabled {
  cursor: no-drop;
}

.error-text {
  color: var(--text-error);
  font-size: 14px;
  font-weight: 500;
}

.text-primary {
  color: var(--primary);
  font-size: 14px;
  line-height: 1.2;
}

.justify-end {
  justify-content: flex-end;
}
